*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial';
    scroll-behavior: smooth;
    transition: .5s;
}
body{
    padding-right: 0px !important;
    /* overflow: hidden !important; */
}  