.cardConnectStatusPage {
  .cardConnectStatusHeader {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 50px;
    }
  }
  .cardConnectStatus{
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 50px auto;
    p{
      text-align: center;
    }
    img{
      width: 50px;
      height: auto;
    }
  }
}
@media screen and (max-width: 450px) {
    .cardConnectStatusPage {
      .cardConnectStatusHeader {
        height: 65px !important;
        img {
          width: auto;
          height: 35px;
        }
      }
    }
  }