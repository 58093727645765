.forgotPasswordPage {
  .forgotHeader {
    width: 100%;
    height: 100px;
    // background-color: #12d51a;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 50px;
      color: white;
    }
  }
  .forgotForm{
    width:400px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 50px auto;
    position: relative;
    p{
        font-size: 18px !important;
        font-family: 'Poppins';
        margin-bottom: 5px;
    }
    input{
        width: 100%;
        height: 20px !important;
        border-radius: 4px;
    }
    button{
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: none;
        background-color: #A3BDA0;
        color: white;
        margin-top: 10px;
    }
  }
  .successfullyChanged{
    width: 100%;
    height: 320px;
    background-color: white;
    position: absolute;
    top: 100px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: green;
    img{
      width: 50px;
      height: auto;
      // margin-right: 10px;
      margin-bottom: 30px;
    }
    p{
      text-align: center;
    }
  }
  .errorMessage{
    width: 100%;
    height: 320px;
    background-color: white;
    position: absolute;
    top: 100px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: red;
    img{
      width: 50px;
      height: auto;
      // margin-right: 10px;
      margin-bottom: 30px;
    }
    p{
      text-align: center; 
    }
  }
}


@media screen and (max-width:450px) {
  .forgotHeader{
    height: 65px !important;
    img{
      width: auto;
      height: 35px !important;
    }
  }
  .forgotForm{
    width: 310px !important;
  }
}
@media screen and (max-width:360px) {
  .forgotForm{
    width: 290px !important;
    div{
      font-size: 14px !important;
    }
  }
}