button {
  cursor: pointer;
}

header {
  width: 100%;
  height: 75px;
  background-color: rgba(255, 255, 255, 0.978);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 7%;
  position: fixed;
  z-index: 1111;
  transition: transform 0.2s ease-in-out;
  transition: 0.5s;

  .hambMenu {
    display: none;
  }

  img {
    width: 130px;
    height: auto;
    cursor: pointer;
    transition: 0.5s;
  }

  .navBar {
    width: 820px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;

    li {
      list-style: none;
      font-size: 17px;
      font-family: "Arial";

      a {
        text-decoration: none;
        color: black;
        transition: .1s;
      }

      a:hover {
        color: rgba(18, 213, 26, 0.7) !important;
        transition: .1s;
      }
    }

    .headerDialog {
      button {
        width: 220px;
        height: 48px;
        background: linear-gradient(
                        90deg,
                        #03d187 -2.6%,
                        #1ac5ba -2.59%,
                        #12d51a 100%
        );
        border-radius: 8px;
        border: none;
        color: white;
        font-size: 18px;
      }
    }
  }

  .lang {
    //width: 20px;
    position: absolute;
    right: 10px;
    border-radius: 50%;
    //height: 20px;
    bottom: 13.5px;
  }
  .lang1{
    //width: 20px;
    position: absolute;
    right: 10px;
    border-radius: 50%;
    //height: 20px;
    bottom: 13.5px;
  }
}

header.hide {
  transform: translateY(-100%);
}

.Tip {
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  padding-top: 135px;
  padding-left: 15%;
  transition: 0.2s;

  .tipBox {
    width: 460px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    z-index: 4;

    p {
      line-height: 24px;
    }

    .buttons {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 220px;
        height: 48px;
        background: linear-gradient(
                        90deg,
                        #03d187 -2.6%,
                        #1ac5ba -2.59%,
                        #12d51a 100%
        );
        border-radius: 8px;
        border: none;
        color: white;
        font-size: 18px;
      }

      a {
        display: flex;
        align-items: center;
        color: #62b554;
        margin-right: 10px;
        text-decoration: none;
        font-size: 16px;
      }
    }
  }

  .images {
    width: 50%;
    height: auto;
    position: relative;

    img {
      position: absolute;
    }
  }
}

.swiper {
  width: 100%;
  height: 500px;
  display: flex;

  .swiperBox {
    width: 456px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 170px;

    h1 {
      text-align: center;
    }

    p {
      line-height: 24px;
      margin-top: 30px;
    }

    div {
      width: auto;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      button {
        width: 216px;
        height: 48px;
        background: linear-gradient(
                        90deg,
                        #03d187 -2.6%,
                        #1ac5ba -2.59%,
                        #12d51a 100%
        );
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-size: 18px;
        color: white;
      }

      p {
        display: flex;
        cursor: pointer;

        img {
          margin-right: 15px;
        }

        margin-top: 0px;
        color: #62b554;
      }
    }
  }
}

//:focus{
//  outline: none;
//}
.howDoesItWork {
  width: 100%;
  height: 770px;
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    margin-bottom: 30px;
    transition: 0.5s;
  }

  .menuDiv {
    width: 630px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    z-index: 111;

    div {
      a {
        text-decoration: none;
        color: #28282f66;
      }
    }

    .divActive {
      width: 200px;
      height: 48px;
      background-color: white;
      border: 2px solid #03d187;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      z-index: 11;
      cursor: pointer;
      color: black;
      transition: 0.1s !important;

      a {
        text-decoration: none;
        color: #28282f66;
      }
    }

    .divUnactive {
      width: 200px;
      height: 48px;
      background-color: white;
      outline: none;
      border: 2px solid #bfedbf;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      z-index: 11;
      cursor: pointer;
      color: black;
      transition: 0.1s !important;

      a {
        text-decoration: none;
        color: #28282f66;
      }
    }
  }

  .routeDiv {
    width: 100%;
    height: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Guest {
    width: 70%;
    height: 500px;
    display: flex;
    justify-content: space-around;
    transition: 0.5s;

    .arrow {
      width: 80px;
      height: 30px;
      margin-top: 160px;
      transition: 0.5s;
    }

    div {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 250px;
        height: auto;
        transition: 0.5s;
      }

      p {
        width: 100%;
        text-align: center;
        line-height: 24px;
        margin-top: 40px;
      }
    }
  }

  .Employee {
    width: 75%;
    height: 500px;
    display: flex;
    justify-content: space-around;

    .EmpDiv {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 250px;
        height: auto;
      }

      p {
        width: 299px;
        text-align: center;
        line-height: 24px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .div {
        width: 110%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        img {
          width: 155px;
          height: 43px;
        }
      }
    }
  }

  .Institution {
    width: 75%;
    height: 500px;
    display: flex;
    justify-content: space-around;

    div {
      width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      img {
        width: 330px;
        height: auto;
      }

      p {
        width: 299px;
        text-align: center;
        line-height: 24px;
        margin-top: 40px;
      }

      .arrow1 {
        width: 50px;
        height: 18px;
        margin-top: 160px;
      }
    }
  }
}

.advantages {
  max-width: 100%;
  height: 900px;
  background-image: url("imgs/advantages.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 115px;
  background-position: center;

  h1 {
    font-size: 40px;
  }

  p {
    line-height: 24px;
  }

  .advBox {
    width: 1140px;
    height: 82%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s;

    .advDiv {
      width: 250px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: 0.5s;

      div {
        width: 100%;
        height: 226px;
        border: 1px solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 20px 0px;
        border-radius: 8px;
        background-color: white;
        border: none;

        img {
          width: 120px;
        }

        p {
          width: 230px;
          text-align: center;
          font-size: 15px;
        }
      }
    }

    .advDiv1 {
      width: 250px;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: 0.5s;

      .connection {
        width: 100%;
        height: 368px;
        border-radius: 8px;
        background-color: white;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 20px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        border: 2.4px solid #12d51a;

        h1 {
          font-size: 20px;
        }

        p {
          width: 235px;
          text-align: center;
          line-height: 32px;
        }

        img {
          width: 70px;
        }

        button {
          width: 220px;
          height: 45px;
          background: linear-gradient(
                          90deg,
                          #03d187 -2.6%,
                          #1ac5ba -2.59%,
                          #12d51a 100%
          );
          border-radius: 8px;
          border: none;
          font-size: 18px;
          color: white;
          cursor: pointer;
          margin-bottom: 7px;
        }
      }

      .lang {
        width: 100%;
        height: 85px;
        border-radius: 8px;
        background-color: white;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          width: 100%;
          height: 30px;
          display: flex !important;
          justify-content: center;
          align-items: center;

          img {
            width: 35px;
            margin: 0px 5px;
            border-radius: 2px;
          }
        }

        p {
          text-align: center;
          line-height: 24px;
        }
      }
    }
  }
}

.HowToPay {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 70px 0px 70px;

  h1 {
    font-size: 40px;
  }

  div {
    width: 95%;
    height: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
      width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 250px;
      }

      p {
        width: 250px;
        text-align: center;
        line-height: 24px;
        margin-top: 40px;
      }
    }
  }
}

.FreeConnect {
  width: 100%;
  height: 331px;
  background-image: url("./imgs/FreeConnectBcgr.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;

  .sums {
    min-width: 60%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 70px;
    transition: 0.5s;

    div {
      width: 200px;
      height: 200px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      h1 {
        font-size: 40px;
        color: #3300ff;
        margin-bottom: 5px;
        font-family: "Poppins", sans-serif;
      }

      p {
        width: 170px;
        text-align: center;
        line-height: 24px;
        color: #636373;
      }
    }
  }

  .freeCnct {
    width: 350px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h1 {
      font-size: 24px;
    }

    p {
      line-height: 32px;
    }

    button {
      width: 350px;
      height: 48px;
      background: linear-gradient(
                      90deg,
                      #03d187 -2.6%,
                      #1ac5ba -2.59%,
                      #12d51a 100%
      );
      border-radius: 8px;
      border: none;
      font-size: 18px;
      color: white;
    }
  }
}

.OurPartners {
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 70px 0px;

  .OurPartnersH1 {
    font-size: 40px !important;
  }

  .partners {
    width: 100%;
    min-height: 250px;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .mainContainer {
    width: 100%;
    min-height: 250px;
    background: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    position: relative;
    top: 0;
    left: 0;
    width: 210px;
    height: 200px;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.8s ease;
    margin: 10px 2px;

    img {
      border-radius: 8px !important;
    }
  }

  .card:hover {
    transform: rotateY(180deg);
  }

  .front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    color: #333;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 18.5px 16px;
    text-align: left;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

    img {
      max-width: fit-content;
      height: 50px;
      border-radius: 5px !important;
    }

    h1 {
      font-size: 14px;
      line-height: 25px;
    }

    p {
      font-size: 14px;
      line-height: 25px;
    }

    transform: rotateY(180deg);
  }

  .partner-ad {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .PartnerApp {
      width: 830px;
      height: 360px;
      background-color: #fff;
      background: linear-gradient(90deg, #03d187 0%, #12d51a 100%);
      border-radius: 8px;
      display: flex;
      align-items: end;
      margin-right: 50px;

      .partnerAppBox1 {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 25px 0px;

        h1 {
          font-size: 24px;
          color: white;
          line-height: 36px;
          width: 387px;
        }

        p {
          font-size: 18px;
          line-height: 28px;
          color: white;
          width: 387px;
        }

        div {
          width: 387px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          padding-right: 30px;

          img {
            width: 170px;
            height: 48px;
          }
        }
      }

      img {
        width: 250px;
      }
    }

    .ad {
      width: 360px;
      height: 200px;
      // background: linear-gradient(143.64deg, #F1C9B2 -0.04%, #F1D986 99.96%);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }
}

.partRev {
  .PartnersReview {
    width: 85%;
    display: flex;
    min-height: 340px;
    padding-top: 20px;
    padding-left: 90px;
    padding-right: 90px;
    margin: 0 auto;

    h1 {
      font-size: 20px;
      line-height: 23px;
      font-weight: 400;
    }

    .status {
      font-size: 14px;
      color: #12d51a;
      line-height: 16px;
    }

    .review {
      width: 278px;
      text-align: left;
      line-height: 24px;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background: #d6cdfb;
    }

    .swiper-slide {
      width: 320px !important;

      h1 {
        margin-bottom: 10px;
      }

      .status {
        margin-bottom: 5px;
      }
    }
  }
}

.SubscriptionEmail {
  width: 1200px;
  height: 160px;
  border-radius: 8px;
  position: relative;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./imgs/FreeConnectBcgr.png");
  background-position: center;

  .subscriptionBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .sub {
      width: 320px;
      height: 80%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .email {
      width: 480px;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      input {
        width: 300px;
        height: 60px;
        background-color: white;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        position: relative;
        border: none;
        outline: none;
        font-size: 18px;
        color: rgb(125 125 125);
        padding-left: 18px;
      }

      input::placeholder {
        color: #dddcdc;
        font-size: 18.5px;
      }

      button {
        width: 180px;
        height: 60px;
        background: linear-gradient(
                        90deg,
                        rgba(3, 209, 135, 0.6) 0%,
                        rgba(18, 213, 26, 0.6) 100%
        );
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: none;
        color: white;
        position: absolute;
        right: 0px;
        cursor: pointer;
      }

      .buttonActive {
        width: 180px;
        height: 60px;
        background: linear-gradient(
                        90deg,
                        rgb(3, 209, 134) 0%,
                        rgb(18, 213, 25) 100%
        );
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: none;
        color: white;
        position: absolute;
        right: 0px;
        cursor: pointer;
      }
    }
  }
}

.OftenGivenQuestions {
  width: 70%;
  min-height: 381px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
  }

  .questionBox {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;
    margin-top: 35px;

    h1 {
      font-size: 16px;
      margin-top: 5px;
    }

    div {
      div {
        display: flex;
        justify-content: space-between;

        img {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }

      p {
        width: 820px;
        line-height: 24px;
        margin-top: 20px;
      }
    }
  }
}

.FeedBack {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 40px;
    line-height: 46px;
    color: #28282f;
    margin-bottom: 30px;
  }

  div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    transition: 0.3s;

    .text {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: start;

      p {
        line-height: 28px;
        color: #2b2a29;
        margin-bottom: 25px;
      }
    }

    .input {
      width: 520px;
      min-height: 470px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 40px 25px;
      position: relative;

      .errorP {
        position: absolute;
        top: 5px;
        color: red;
      }

      div {
        display: flex;
        justify-content: space-around;
        position: relative;

        .MuiInputBase-root {
          margin: 5px 0px;
          border: 0px solid #dddcdc;
          color: #575756;
        }

        label {
          color: #dddcdc;
          margin: 4px auto;
        }
      }

      textarea {
        min-width: 100%;
        max-width: 100%;
        max-height: 300px;
        border: 1px solid #c4c4c4;
        color: #575756;
        font-size: 16px;
        padding: 16.5px 14px;
        margin: 5px 0px;
        outline: none;
        border-radius: 4.5px;
        transition: 0s;
      }

      textarea::placeholder {
        color: #dddcdc;
      }

      textarea:hover {
        border: 0.5px solid rgb(29, 29, 29);
      }

      button {
        width: 100%;
        height: 48px;
        background: linear-gradient(
                        90deg,
                        rgba(3, 209, 134, 0.314) 0%,
                        rgba(18, 213, 25, 0.368) 100%
        );
        border-radius: 8px;
        color: rgb(255, 255, 255);
        border: none;
        cursor: pointer;
      }

      button:hover {
        background: linear-gradient(90deg, rgba(3, 209, 134, 0.615) 0%, rgba(18, 213, 25, 0.604) 100%) !important;
      }

      .buttonActive1 {
        width: 100%;
        height: 48px;
        background: linear-gradient(
                        90deg,
                        rgb(3, 209, 134) 0%,
                        rgb(18, 213, 25) 100%
        );
        border-radius: 8px;
        color: white;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.Footer {
  width: 100%;
  height: 500px;
  margin-top: -170px;
  background-image: url("./imgs/footerBg.svg");
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: start;
  padding-top: 280px;
  transition: 0.5s !important;

  h1 {
    font-size: 18px;
    margin-bottom: 20px !important;
  }

  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 20px !important;
  }

  .tipsLogo {
    width: 306px;
    height: 150px;
    display: flex;
    flex-direction: column;

    img {
      width: 190px;
      margin-bottom: 34.5px !important;
    }
  }

  .aboutUs {
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .forCooperation {
    width: 280px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin-bottom: 7px;
      &:last-of-type {
        margin-bottom: 15px;

      }
    }
  }

  .feedBackFooter {
    width: 160px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.dialog {
  .feedBackDialogButton:hover {
    background: linear-gradient(90deg, rgba(3, 209, 134, 0.615) 0%, rgba(18, 213, 25, 0.604) 100%) !important;

  }
}


@media screen and (max-width: 1450px) {
  .Institution {
    width: 80% !important;
  }
}

@media screen and (max-width: 1400px) {
  .PartnersReview {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 1375px) {
  .Footer {
    div {
      div {
        h1 {
          font-size: 16px !important;
        }

        p {
          font-size: 16px !important;
        }

        a {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .Guest {
    width: 75% !important;
  }

  .Institution {
    width: 85% !important;
  }
}

@media screen and (max-width: 1350px) {
  .HowToPay {
    padding: 70px 20px 0px 20px;
  }
}

@media screen and (max-width: 1350px) {
  .FreeConnect {
    .sums {
      div {
        width: 170px !important;
        height: 170px !important;
      }
    }

    .freeCnct {
      width: 320px !important;
      height: 170px !important;

      button {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .FeedBack {
    width: 80% !important;
  }

  .Footer {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1300px) {
  .PartnerApp {
    margin-right: 20px !important;
    width: 720px !important;
    justify-content: space-around;

    .partnerAppBox1 {
      width: 400px !important;
      align-items: flex-start !important;

      h1 {
        font-size: 22px !important;
        line-height: 32px !important;
      }

      p {
        font-size: 16px !important;
        width: 340px !important;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .Tip {
    padding-left: 8%;
  }
}

@media screen and (max-width: 1250px) {
  .Guest {
    width: 80% !important;
  }

  .Employee {
    width: 80% !important;
  }

  .Institution {
    width: 90% !important;
  }
}

@media screen and (max-width: 1250px) {
  .HowToPay {
    padding: 70px 0px 0px 0px;

    /*div {
      width: 85% !important;
    }*/
  }
}

@media screen and (max-width: 1250px) {
  .Footer {
    padding-top: 300px !important;

    a {
      font-size: 16px !important;
    }

    .tipsLogo {
      img {
        width: 170px !important;
      }

      height: 130px !important;
    }

    .aboutUs {
      height: 130px !important;
    }

    .forCooperation {
      height: 110px !important;
    }

    .feedBackFooter {
      height: 130px !important;
    }
  }
}

@media screen and (max-width: 1230px) {
  .SubscriptionEmail {
    width: 1100px !important;
  }
}

@media screen and (max-width: 1221px) {
  .OftenGivenQuestions {
    p {
      width: 90% !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .FreeConnect {
    .sums {
      div {
        width: 150px !important;
        height: 150px !important;

        h1 {
          font-size: 32px !important;
        }

        p {
          font-size: 13px !important;
          width: 130px !important;
          line-height: 18px !important;
        }
      }
    }

    .freeCnct {
      width: 280px !important;
      height: 150px !important;

      h1 {
        font-size: 22px !important;
      }

      p {
        font-size: 14px !important;
        width: 90% !important;
      }

      button {
        height: 40px !important;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .Guest {
    width: 85% !important;
  }

  .Employee {
    width: 85% !important;
  }

  .Institution {
    margin-top: 80px !important;
    width: 93% !important;

    .ar1 {
      left: 280px !important;
    }

    div {
      img {
        width: 300px !important;
      }

      .arrow1 {
        width: 45px !important;
        height: 16px !important;
        margin-top: 135px !important;
      }

      .ar2 {
        right: 275px !important;
      }

      p {
        font-size: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 1170px) {
  .FeedBack {
    width: 90% !important;
  }

  .Footer {
    margin-top: -80px !important;
  }
}

@media screen and (max-width: 1160px) {
  .advantages {
    .advBox {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  header {
    padding: 0% 7% 0% 2% !important;

    .lang {
      right: 5px;
    }

    .lang1 {
      right: 5px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .Tip {
    padding-left: 3%;
  }
}

@media screen and (max-width: 1150px) {
  .HowToPay {
    div {
      flex-wrap: wrap !important;

      div {
        width: 300px !important;
      }
    }

    margin-bottom: 500px;
  }
}

@media screen and (max-width: 1140px) {
  .PartnerApp {
    width: 600px !important;
    height: 300px !important;
    justify-content: space-around;

    img {
      width: 220px !important;
    }

    .partnerAppBox1 {
      width: 300px !important;
      align-items: flex-start !important;

      h1 {
        font-size: 19px !important;
        line-height: 32px !important;
        width: 360px !important;
      }

      p {
        font-size: 15px !important;
        width: 280px !important;
      }

      div {
        width: 340px !important;

        img {
          width: 150px !important;
          height: 42px !important;
        }
      }
    }
  }

  .ad {
    width: 300px !important;
  }
}

@media screen and (max-width: 1130px) {
  .SubscriptionEmail {
    width: 1000px !important;
  }
}

@media screen and (max-width: 1100px) {
  .Guest {
    width: 90% !important;
  }

  .Employee {
    width: 90% !important;
  }

  .Institution {
    width: 95% !important;

    .ar1 {
      left: 275px !important;
    }
  }
}

@media screen and (max-width: 1100px) {
  .advBox {
    justify-content: center !important;

    .advDiv {
      width: 220px !important;
      height: 440px !important;
      margin: 0px 11px;

      div {
        height: 196px !important;

        img {
          width: 70px !important;
        }

        p {
          width: 90% !important;
        }
      }

      p {
        font-size: 14px !important;
      }
    }

    .advDiv1 {
      width: 220px !important;
      height: 440px !important;
      margin: 0px 11px !important;

      .connection {
        height: 325px !important;

        img {
          width: 60px !important;
        }

        h1 {
          font-size: 18px !important;
        }

        p {
          width: 94% !important;
          font-size: 14px !important;
          line-height: 22px !important;
        }

        button {
          width: 180px !important;
          height: 40px !important;
          font-size: 16px !important;
        }
      }

      .lang {
        height: 70px !important;

        p {
          width: 95% !important;
          font-size: 14px !important;
          line-height: 21px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .FreeConnect {
    .sums {
      margin-right: 40px !important;
    }

    .freeCnct {
      button {
        width: 250px !important;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .Tip {
    padding-left: 1%;
    align-items: start !important;

    .images {
      width: 40% !important;
      height: 500px;
      margin-right: 0px;

      .BgGreen,
      .BgPurple {
        width: 650px !important;
        transition: 0.5s;
      }

      .PhoneImg {
        width: 420px !important;
        transition: 0.5s;
      }
    }
  }

  .howDoesItWork {
    margin-top: 20px !important;
  }

  header {
    .navBar {
      width: 750px !important;

      li {
        font-size: 16px !important;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .Guest {
    width: 95% !important;
  }

  .Employee {
    width: 95% !important;
  }

  .Institution {
    div {
      .ar1 {
        left: 268px !important;
      }

      .ar2 {
        right: 268px !important;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .HowToPay {
    div {
      width: 95% !important;
    }
  }
}

@media screen and (max-width: 1050px) {
  .Footer {
    h1 {
      font-size: 16px !important;
    }

    i {
      font-size: 20px !important;
    }

    padding-top: 300px !important;

    a {
      font-size: 15px !important;
    }

    .tipsLogo {
      img {
        width: 170px !important;
      }

      width: 255px !important;
      height: 130px !important;
    }

    .aboutUs {
      width: 164px !important;
      height: 130px !important;
    }

    .forCooperation {
      width: 245px !important;
      height: 110px !important;
    }

    .feedBackFooter {
      width: 140px !important;
      height: 130px !important;
    }
  }
}

@media screen and (max-width: 1030px) {
  .FreeConnect {
    padding-left: 20px !important;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1030px) {
  .SubscriptionEmail {
    width: 850px !important;

    .subscriptionBox {
      .email {
        width: 400px !important;

        input {
          height: 50px !important;
          width: 270px !important;
        }

        button {
          width: 130px !important;
          height: 50px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .FeedBack {
    width: 95% !important;
  }
}

@media screen and (max-width: 1017px) {
  .FreeConnect {
    height: 400px !important;
    background-position: center;
    text-align: center;

    .sums {
      min-width: 90% !important;
      margin-right: 0px !important;

      div {
        width: 200px !important;
      }
    }

    .freeCnct {
      align-items: center;

      p {
        width: 210px !important;
        line-height: 23px !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .Guest {
    .arrow {
      width: 60px !important;
      height: 23px !important;
    }

    div {
      width: 250px !important;

      img {
        width: 240px !important;
      }

      p {
        font-size: 15px !important;
      }
    }
  }

  .Employee {
    .EmpDiv {
      width: 250px !important;

      img {
        width: 240px !important;
      }

      div {
        img {
          width: 130px !important;
          height: 35px !important;
        }
      }

      p,
      li {
        width: 100% !important;
        font-size: 15px !important;
      }
    }
  }

  .Institution {
    div {
      img {
        width: 270px !important;
      }

      .ar1 {
        top: 125px !important;
        left: 250px !important;
      }

      .ar2 {
        top: 125px !important;
        right: 250px !important;
      }

      p {
        font-size: 14px !important;
        width: 250px !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .Tip {
    .images {
      width: 40% !important;
      height: 500px;
      margin-right: 0px;

      .BgGreen,
      .BgPurple {
        width: 600px !important;
        transition: 0.55s;
      }

      .PhoneImg {
        width: 380px !important;
        transition: 0.5s;
      }
    }
  }

  .howDoesItWork {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 985px) {
  .advantages {
    .advBox {
      .advDiv {
        width: 200px !important;
        height: 400px !important;

        div {
          height: 180px !important;

          img {
            width: 55px !important;
          }

          p {
            font-size: 12px !important;
          }
        }
      }

      .advDiv1 {
        width: 200px !important;
        height: 400px !important;

        .connection {
          height: 300px !important;

          img {
            width: 50px !important;
          }

          h1 {
            font-size: 17px !important;
          }

          p {
            font-size: 12px !important;
            line-height: 24px !important;
          }

          button {
            width: 160px !important;
            height: 38px !important;
            font-size: 14px !important;
          }
        }

        .lang {
          height: 60px !important;

          div {
            img {
              width: 28px !important;
            }
          }

          p {
            font-size: 12px !important;
            line-height: 18px !important;
            margin-bottom: 1px;
          }
        }
      }
    }
  }

  header {
    .navBar {
      width: 720px !important;
    }
  }
}

@media screen and (max-width: 970px) {
  .Tip {
    .images {
      width: 40% !important;
      height: 500px;
      margin-right: 0px;

      .BgGreen,
      .BgPurple {
        width: 550px !important;
        transition: 0.5s;
      }

      .PhoneImg {
        width: 330px !important;
        transition: 0.5s;
      }
    }
  }

  .howDoesItWork {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 970px) {
  .FeedBack {
    .feedBox {
      .text {
        width: 300px !important;
        font-size: 15px !important;

        p {
          line-height: 22px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .partner-ad {
    flex-direction: column;
    align-items: center;

    .PartnerApp {
      width: 600px !important;
      height: 300px !important;
      margin-bottom: 50px !important;
      margin-right: 0px !important;
    }
  }

  .ad {
    width: 270px !important;
  }
}

@media screen and (max-width: 950px) {
  header .navBar {
    width: 550px;

    li {
      font-size: 17px;
    }
  }
  header {
    .lang {
      display: none;
    }

    .lang1 {
      display: none;
    }

    .hambLang {
      width: 25px !important;
      position: absolute !important;
      right: 30px;
      border-radius: 50%;
      height: 25px;
    }
  }
}

@media screen and (max-width: 950px) {
  .navBar {
    display: none !important;
  }

  .hambMenu {
    display: block !important;

    a {
      text-decoration: none !important;
      font-size: 14px !important;
    }
  }

  .hambMenuDiv {
    margin: 10px 15px !important;

    a {
      font-size: 15px !important;
    }

    /*a:hover{
      color: green !important;
    }*/

    button {
      width: 180px !important;
      height: 40px !important;
      font-size: 16px !important;
    }
  }

  .MuiPaper-root {
    top: 55px !important;
  }
}

@media screen and (max-width: 947px) {
  .Guest {
    flex-wrap: wrap;

    .arrow {
      display: none;
    }

    div {
      width: 300px !important;
      height: 86% !important;

      p {
        line-height: 20px !important;
        font-size: 14px !important;
      }
    }
  }
  .HowToPay {
    div {
      div {
        p {
          font-size: 14px !important;
        }
      }
    }
  }
  .Tip {
    .tipBox {
      width: 430px !important;

      p {
        font-size: 14px !important;
      }

      .buttons {
        div {
          button {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  .Employee {
    flex-wrap: wrap;

    .EmpDiv {
      width: 300px !important;
      height: 95% !important;

      div {
        img {
          width: 155px !important;
          height: 43px !important;
        }
      }
    }
  }

  .Institution {
    flex-wrap: wrap;
    margin-top: 0px !important;

    div {
      width: 330px !important;
      height: 82% !important;

      p {
        margin-top: 40px !important;
      }

      img {
        width: 330px !important;
      }

      .arrow1 {
        display: none;
      }
    }
  }

  .advantages {
    margin-top: 300px !important;
  }
}

@media screen and (max-width: 930px) {
  .PartnerApp {
    margin-right: 20px !important;
    width: 515px !important;
    height: 260px !important;
    justify-content: space-around;
    padding: 0px 10px !important;

    img {
      width: 180px !important;
    }

    .partnerAppBox1 {
      width: 300px !important;
      align-items: flex-start !important;
      padding: 5px 0px !important;

      h1 {
        font-size: 17px !important;
        line-height: 32px !important;
        width: 300px !important;
      }

      p {
        font-size: 14px !important;
        width: 270px !important;
        line-height: 22px !important;
      }

      div {
        width: 300px !important;

        img {
          width: 128px !important;
          height: 35px !important;
        }
      }
    }
  }

  .ad {
    margin-top: 0px !important;

    h1 {
      font-size: 18px !important;
    }

    p {
      font-size: 14px !important;
      line-height: 20px !important;
      width: 240px !important;
    }

    button {
      width: 210px !important;
      height: 40px !important;
    }
  }
}

@media screen and (max-width: 930px) {
  .howDoesItWork {
    h1 {
      font-size: 35px !important;
    }
  }

  .advantages {
    h1 {
      font-size: 35px !important;
    }
  }

  .HowToPay {
    h1 {
      font-size: 35px !important;
    }
  }

  .OurPartners {
    .OurPartnersH1 {
      font-size: 35px !important;
    }
  }

  .partRev {
    .revH1 {
      font-size: 35px !important;
    }
  }

  .FeedBack {
    h1 {
      font-size: 35px !important;
    }
  }
}

@media screen and (max-width: 930px) {
  .OftenGivenQuestions {
    .oftH1 {
      font-size: 35px !important;
    }
  }
}

@media screen and (max-width: 920px) {
  .Footer {
    padding-top: 150px !important;

    .tipsLogo {
      width: 310px !important;
      padding-left: 6%;
      margin-bottom: 20px !important;

      img {
        margin-bottom: 33px !important;
      }
    }

    .aboutUs {
      width: 285px !important;
      padding-left: 13%;
    }

    .forCooperation {
      width: 310px !important;
      padding-left: 6%;
    }

    .feedBackFooter {
      width: 285px !important;
      padding-left: 13%;
    }
  }
}

@media screen and (max-width: 910px) {
  .Tip {
    height: 550px !important;

    .tipBox {
      margin-top: 0px !important;
    }

    .images {
      width: 40% !important;
      height: 500px;
      margin-right: 0px;

      .BgGreen,
      .BgPurple {
        width: 480px !important;
        transition: 0.5s;
      }

      .PhoneImg {
        width: 310px !important;
        transition: 0.5s;
        right: 115px !important;
        top: -20px !important;
      }
    }
  }
}

@media screen and (max-width: 905px) {
  .advantages {
    height: 1100px !important;

    h1 {
      margin-bottom: 30px !important;
    }
  }
}

@media screen and (max-width: 880px) {
  .SubscriptionEmail {
    width: 700px !important;

    .subscriptionBox {
      .sub {
        width: 300px !important;

        h1 {
          font-size: 20px !important;
        }

        p {
          font-size: 14px !important;
          line-height: 22px !important;
        }
      }

      .email {
        width: 350px !important;

        input {
          height: 45px !important;
          width: 235px !important;
        }

        button {
          width: 115px !important;
          height: 45px !important;
          font-size: 13px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .FeedBack {
    .feedBox {
      .input {
        width: 450px !important;
        min-height: 420px !important;
        padding: 25px 25px !important;

        input {
          height: 20px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .Tip {
    .images {
      width: 300px !important;
      height: 300px;
      margin-right: 0px;

      .BgGreen,
      .BgPurple {
        width: 440px !important;
        transition: 0.5s;
      }

      .PhoneImg {
        width: 290px !important;
        transition: 0.5s;
        right: 90px !important;
        top: -35px !important;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  header {
    img {
      width: 110px;
    }

    .navBar {
      width: 500px;

      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 815px) {
  .FeedBack {
    .feedBox {
      flex-direction: column;
      align-items: center;

      .text {
        width: 500px !important;
        text-align: center;

        p {
          font-size: 18px;
        }
      }

      .input {
        width: 450px !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Tip {
    min-height: 750px !important;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center !important;
    padding-top: 70px;
    transition: 0.5s;

    .images {
      top: 20px;
      margin-bottom: 30px;

      .BgGreen,
      .BgPurple {
        display: none;
        transition: 0.5s;
      }

      .PhoneImg {
        position: static !important;
      }
    }

    .tipBox {
      margin-bottom: 30px !important;
    }
  }
}

@media screen and (max-width: 760px) {
  header {
    padding: 0px 3%;

    img {
      width: 110px;
    }

    .navBar {
      width: 500px;

      li {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .Institution {
    div {
      width: 300px !important;

      img {
        width: 300px !important;
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .SubscriptionEmail {
    width: 95% !important;
    padding-top: 20px;
    height: 250px !important;
    background-position: center !important;

    .subscriptionBox {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 710px) {
  .menuDiv {
    width: 540px !important;
    transition: 0.5s !important;

    div {
      width: 170px !important;
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 700px) {
  .Institution {
    div {
      img {
        width: 290px !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .FreeConnect {
    .sums {
      div {
        width: 140px !important;
        height: 140px !important;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .partRev {
    .PartnersReview {
      .swiper-slide {
        width: 250px !important;
        // height: 180px !important;

        h1 {
          font-size: 16px !important;
        }

        .status {
          i {
            font-size: 13px !important;
          }

          font-size: 13px !important;
        }

        .review {
          font-size: 14px !important;
          width: 240px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 665px) {
  .OftenGivenQuestions {
    width: 80% !important;
  }
}

@media screen and (max-width: 650px) {
  .FreeConnect {
    .sums {
      min-width: 95% !important;
    }
  }
}

@media screen and (max-width: 649px) {
  .Guest {
    div {
      img {
        width: 200px !important;
      }

      p {
        width: 250px !important;
      }
    }
  }

  .Employee {
    .EmpDiv {
      img {
        width: 200px !important;
      }

      p {
        width: 250px !important;
      }

      div {
        width: 250px !important;
        height: 95% !important;

        img {
          width: 120px !important;
          height: 32px !important;
        }
      }
    }
  }

  .Institution {
    div {
      height: 85% !important;
      margin-bottom: 30px;

      img {
        width: 330px !important;
      }
    }
  }

  .advantages {
    margin-top: 730px !important;
  }
}

@media screen and (max-width: 649px) {
  .HowToPay {
    margin-bottom: 1150px !important;

    h1 {
      margin-bottom: 35px !important;
    }

    div {
      div {
        height: 80% !important;

        img {
          width: 200px !important;
          height: 246px !important;
        }

        .borderedImage {
          border-top: 5px solid rgb(209, 205, 249) !important;
          border-left: 4.5px solid rgb(209, 205, 249) !important;
          border-right: 4.5px solid rgb(209, 205, 249) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 648px) {
  .card {
    width: 175px !important;
    height: 175px !important;

    .front {
      img {
        height: 64px !important;
      }
    }
  }
}

@media screen and (max-width: 637px) {
  .Footer {
    padding: 150px 20px 0px 20px !important;

    .tipsLogo {
      width: 255px !important;
      padding-left: 0%;
    }

    .aboutUs {
      width: 165px !important;
      padding-left: 0%;
    }

    .forCooperation {
      width: 255px !important;
      padding-left: 0%;
    }

    .feedBackFooter {
      width: 165px !important;
      padding-left: 0%;
    }
  }
}

@media screen and (max-width: 630px) {
  .partner-ad {
    .PartnerApp {
      width: 515px !important;
      height: 260px !important;
      justify-content: space-around !important;
      padding: 0px 10px !important;

      img {
        width: 180px !important;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .FreeConnect {
    height: 570px !important;

    .sums {
      flex-wrap: wrap;
      justify-content: center !important;

      div {
        margin-bottom: 20px !important;
      }
    }

    .freeCnct {
      align-items: center !important;
      text-align: center !important;
    }
  }
}

@media screen and (max-width: 609px) {
  .OftenGivenQuestions {
    .oftH1 {
      font-size: 30px !important;
    }
  }

  .FeedBack {
    h1 {
      font-size: 30px !important;
    }

    .feedBox {
      .text {
        p {
          font-size: 16px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .menuDiv {
    width: 480px !important;
    transition: 0.5s !important;

    div {
      width: 150px !important;
      font-size: 17px !important;
    }
  }

  .howDoesItWork {
    h1 {
      font-size: 38px;
    }
  }

  .dialog {
    width: 375px !important;
  }
}

@media screen and (max-width: 600px) {
  .FeedBack {
    .feedBox {
      .text {
        width: 450px !important;
        text-align: center;

        p {
          font-size: 15px !important;
        }
      }

      .input {
        width: 450px !important;
      }
    }
  }
}

@media screen and (max-width: 598px) {
  .OftenGivenQuestions {
    width: 90% !important;
  }
}

@media screen and (max-width: 556px) {
  .Footer {
    a {
      font-size: 14px !important;
    }

    .tipsLogo {
      width: 255px !important;
      padding-left: 0%;
    }

    .aboutUs {
      width: 165px !important;
      padding-left: 0%;
    }

    .forCooperation {
      width: 255px !important;
      padding-left: 0%;
    }

    .feedBackFooter {
      width: 165px !important;
      padding-left: 0%;
    }
  }
}

@media screen and (max-width: 550px) {
  .partner-ad {
    .PartnerApp {
      width: 450px !important;
      height: 230px !important;
      justify-content: space-around !important;
      padding: 0px 10px !important;

      img {
        width: 150px !important;
      }

      .partnerAppBox1 {
        width: 270px !important;

        h1 {
          font-size: 16px !important;
          line-height: 22px !important;
        }

        div {
          width: 280px !important;

          img {
            width: 120px !important;
            height: 33px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 549px) {
  .howDoesItWork {
    h1 {
      font-size: 30px !important;
    }
  }

  .advantages {
    h1 {
      font-size: 30px !important;
    }
  }

  .HowToPay {
    h1 {
      font-size: 30px !important;
    }
  }

  .OurPartners {
    .OurPartnersH1 {
      font-size: 30px !important;
    }
  }

  .partRev {
    .revH1 {
      font-size: 30px !important;
    }
  }

  .OftenGivenQuestions {
    .oftH1 {
      font-size: 30px !important;
    }
  }

  .FeedBack {
    h1 {
      font-size: 30px !important;
    }
  }
}

@media screen and (max-width: 540px) {
  .menuDiv {
    width: 430px !important;
    transition: 0.5s !important;

    div {
      width: 135px !important;
      font-size: 15px !important;
    }
  }

  .howDoesItWork {
    h1 {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 526px) {
  .OftenGivenQuestions {
    .oftH1 {
      // font-size: 25px !important;
    }

    .questionBox {
      div {
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .FeedBack {
    h1 {
      // font-size: 25px !important;
    }
  }
}

@media screen and (max-width: 522px) {
  .OftenGivenQuestions {
    .questionBox {
      h1 {
        font-size: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .partRev {
    .revH1 {
      // font-size: 23px !important;
    }
  }

  .FeedBack {
    .feedBox {
      .input {
        width: 400px !important;
      }
    }
  }

  .advantages {
    margin-top: 700px !important;
  }

  .HowToPay {
    padding: 0px !important;
    margin-bottom: 1000px !important;

    h1 {
      margin-bottom: 0px !important;
    }
  }

  .OurPartners {
    padding: 5px 0px !important;
    margin-bottom: 70px !important;

    .partners {
      padding-bottom: 10px !important;
      padding-top: 20px !important;
    }
  }

  .SubscriptionEmail {
    width: 90% !important;
    margin: 20px auto;
  }

  .OftenGivenQuestions {
    margin: 0px auto !important;
  }

  .FeedBack {
    .feedBox {
      .text {
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .Institution {
    div {
      margin-bottom: 0px !important;
    }
  }

  .advantages {
    height: 980px !important;
    padding-top: 70px !important;

    h1 {
      margin-bottom: 10px !important;
    }
  }

  .Footer {
    margin-top: -80px !important;
    flex-direction: column;
    justify-content: start !important;
    padding-left: 10% !important;
    height: 700px !important;

    h1 {
      margin-bottom: 10px !important;
    }

    a {
      margin-bottom: 10px !important;
    }

    div {
      margin-bottom: 10px !important;
    }

    .aboutUs {
      height: 110px !important;
    }

    .forCooperation {
      height: 160px !important;
    }

    .feedBackFooter {
      height: 103px !important;
    }

    .tipsLogo {
      margin-bottom: 30px !important;
      height: 90px !important;

      img {
        width: 150px !important;
        margin-bottom: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .Tip {
    padding-left: 0px !important;

    .tipBox {
      align-items: center;
      width: 100% !important;

      h1 {
        font-size: 30px !important;
        text-align: center;
      }

      p {
        width: 390px !important;
        font-size: 15px !important;
      }

      .buttons {
        justify-content: center !important;

        button {
          font-size: 14px !important;
          width: 200px !important;
          margin-right: 20px !important;
        }

        a {
          width: 170px !important;
          margin-right: 0px !important;
          font-size: 14px !important;
        }
      }
    }

    .images {
      width: 250px !important;

      .PhoneImg {
        width: 250px !important;
      }
    }
  }

  @media screen and (max-width: 460px) {
    .Tip {
      padding-left: 0px !important;

      .tipBox {
        width: 100% !important;
      }
    }
  }

  .OftenGivenQuestions {
    .questionBox {
      div {
        div {
          h1 {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .FeedBack {
    .feedBox {
      .text {
        width: 95% !important;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .howDoesItWork {
    h1 {
      font-size: 28px !important;
    }

    .menuDiv {
      width: 385px !important;

      div {
        width: 120px !important;
        font-size: 13px !important;
      }
    }
  }
  .Tip .tipBox h1 {
    font-size: 28px !important;
  }
  .HowToPay h1 {
    font-size: 28px !important;
  }
  .OurPartners .OurPartnersH1 {
    font-size: 28px !important;
  }
  .partRev .revH1 {
    font-size: 28px !important;
  }
  .OftenGivenQuestions .oftH1 {
    font-size: 28px !important;
  }
  .FeedBack h1 {
    font-size: 28px !important;
  }

  .advantages {
    h1 {
      font-size: 28px !important;
    }
  }
}

@media screen and (max-width: 470px) {
  header {
    height: 60px !important;
  }

  .partner-ad {
    .PartnerApp {
      width: 400px !important;
      height: 190px !important;
      justify-content: space-between !important;
      padding: 0px 10px !important;

      img {
        width: 120px !important;
      }

      .partnerAppBox1 {
        width: 240px !important;

        h1 {
          font-size: 14px !important;
          line-height: 22px !important;
        }

        p {
          font-size: 13px !important;
          width: 230px !important;
        }

        div {
          width: 240px !important;
          height: 30px !important;

          img {
            width: 100px !important;
            height: 28px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .OftenGivenQuestions {
    .questionBox {
      div {
        div {
          h1 {
            font-size: 13.5px !important;
          }
        }

        p {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 445px) {
  .Tip {
    padding-left: 0px !important;

    .tipBox {
      width: 100% !important;
    }
  }

  .advantages {
    height: 860px !important;

    .advBox {
      height: 73.5% !important;

      .advDiv {
        width: 160px !important;
        height: 360px !important;

        div {
          height: 160px !important;

          img {
            width: 45px !important;
          }

          p {
            line-height: 17px !important;
          }
        }
      }

      .advDiv1 {
        width: 160px !important;
        height: 360px !important;

        .connection {
          height: 260px !important;
          padding-top: 10px !important;

          img {
            width: 35px !important;
          }

          h1 {
            font-size: 14px !important;
            margin-bottom: 0px !important;
          }

          p {
            line-height: 17px !important;
          }

          button {
            width: 130px !important;
            height: 30px !important;
            font-size: 13px !important;
          }
        }

        .lang {
          height: 60px !important;

          div {
            img {
              width: 20px !important;
            }
          }

          p {
            line-height: 14px !important;
            font-size: 10px !important;
          }
        }
      }
    }
  }

  .FeedBack {
    .feedBox {
      .text {
        p {
          font-size: 14px !important;
        }
      }

      .input {
        width: 380px !important;
      }
    }
  }

  .dialog {
    width: 315px !important;
    height: 410px !important;

    h1 {
      font-size: 23px !important;
      margin-bottom: 15px !important;
    }

    form {
      p {
        top: 320px !important;
        font-size: 13px !important;
      }

      div {
        margin: 3px 0px !important;
      }

      label {
        font-size: 15px !important;
      }

      div {
        input {
          height: 16px !important;
        }
      }

      button {
        width: 200px !important;
        height: 40px !important;
        font-size: 15px !important;
      }
    }
  }
  .SubscriptionEmail {
    .subscriptionBox {
      .email {
        align-items: center;

        form {
          width: 90%;
          height: 100px;
          display: flex;
          flex-direction: column !important;
          justify-content: space-around;
          align-items: center;

          input {
            width: 100% !important;
            border-radius: 8px;
          }

          button {
            position: unset;
            border-radius: 8px;
            width: 100% !important;
            height: 40px !important;
          }

          .buttonActive {
            position: unset;
            border-radius: 8px;
            width: 100% !important;
            height: 40px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .OftenGivenQuestions {
    .questionBox {
      h1 {
        margin-top: 0px !important;
      }

      div {
        div {
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .OurPartners {
    .partner-ad {
      .PartnerApp {
        width: 340px !important;

        .partnerAppBox1 {
          width: 200px !important;

          h1 {
            width: 200px !important;
            font-size: 13.5px !important;
            line-height: 20px !important;
          }

          p {
            width: 200px !important;
            line-height: 18px !important;
          }

          div {
            width: 200px !important;
            padding-right: 10px !important;

            img {
              width: 90px !important;
              height: 26px !important;
            }
          }
        }
      }
    }
  }

  .partRev {
    .revH1 {
      // font-size: 25px !important;
    }

    .PartnersReview {
      .swiper-wrapper {
        .swiper-slide {
          h1 {
            font-size: 14px !important;
          }

          .status {
            font-size: 13px !important;
          }

          .review {
            font-size: 14px !important;
            width: 220px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }

  .SubscriptionEmail {
    width: 90% !important;
  }

  .FeedBack {
    .feedBox {
      .input {
        width: 350px !important;
        min-height: 420px !important;
      }
    }
  }
}

@media screen and (max-width: 405px) {
  .Tip {
    min-height: 680px !important;
    padding-top: 40px !important;

    .tipBox {
      width: 96% !important;

      h1 {
        font-size: 25px !important;
      }

      p {
        width: 330px !important;
        font-size: 13px !important;
      }

      .buttons {
        button {
          width: 160px !important;
          font-size: 14px !important;
        }

        a {
          img {
            width: 18px !important;
            margin-right: 10px !important;
          }

          font-size: 13px !important;
        }
      }
    }

    .images {
      width: 200px !important;
      height: 250px !important;

      .PhoneImg {
        width: 200px !important;
      }
    }
  }

  .howDoesItWork {
    h1 {
      font-size: 25px !important;
    }

    .menuDiv {
      width: 320px !important;

      div {
        width: 100px !important;
        height: 40px !important;
        font-size: 11px !important;
      }
    }

    .routeDiv {
      .Guest {
        div {
          height: 67% !important;

          img {
            width: 150px !important;
          }

          p {
            width: 300px !important;
            font-size: 12px !important;
          }
        }
      }

      .Employee {
        .EmpDiv {
          height: 67% !important;

          img {
            width: 150px !important;
          }

          p,
          ul {
            font-size: 12px !important;
            margin-top: 20px !important;

            li {
              font-size: 12px !important;
            }
          }

          div {
            img {
              width: 120px !important;
            }
          }
        }
      }

      .Institution {
        div {
          height: 65% !important;

          img {
            width: 250px !important;
          }

          p {
            width: 300px !important;
            font-size: 12px !important;
          }
        }
      }
    }

  }

  .advantages {
    margin-top: 390px !important;
  }

  .HowToPay {
    margin-bottom: 570px !important;

    h1 {
      // font-size: 28px !important;
    }

    div {
      div {
        height: 60% !important;

        img {
          width: 150px !important;
          height: 184px !important;
        }

        .borderedImage {
          border-top-left-radius: 20px !important;
          border-top-right-radius: 20px !important;
          border-top: 4px solid rgb(209, 205, 249) !important;
          border-left: 4px solid rgb(209, 205, 249) !important;
          border-right: 4px solid rgb(209, 205, 249) !important;
        }

        p {
          width: 300px !important;
          font-size: 12px !important;
        }
      }
    }
  }

  .FreeConnect {
    padding-left: 0px !important;

    .sums {
      div {
        margin: 7.5px 7.5px !important;
      }
    }

    .freeCnct {
      margin-right: 10px !important;
    }
  }

  .OurPartners {
    .OurPartnersH1 {
      font-size: 28px !important;
    }
  }

  .FeedBack {
    .feedBox {
      .text {
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .SubscriptionEmail {
    .subscriptionBox {
      .email {
        input {
          width: 200px !important;
        }

        button {
          width: 100px !important;
        }
      }
    }
  }
  .Tip .tipBox h1 {
    font-size: 25px !important;
  }
  .HowToPay h1 {
    font-size: 25px !important;
  }
  .OurPartners .OurPartnersH1 {
    font-size: 25px !important;
  }
  .partRev .revH1 {
    font-size: 25px !important;
  }
  .OftenGivenQuestions .oftH1 {
    font-size: 25px !important;
  }
  .FeedBack h1 {
    font-size: 25px !important;
  }

  .advantages {
    h1 {
      font-size: 25px !important;
    }
  }
}

@media screen and (max-width: 380px) {
  .dialog {
    width: 300px !important;
    height: 370px !important;
    padding: 10px 20px 0px 20px !important;

    h1 {
      font-size: 20px !important;
      margin-bottom: 10px !important;
    }

    form {
      p {
        top: 295px !important;
        font-size: 11px !important;
      }

      div {
        margin: 3px 0px !important;
      }

      label {
        font-size: 13px !important;
      }

      div {
        input {
          height: 14px !important;
        }
      }

      button {
        width: 200px !important;
        height: 40px !important;
        font-size: 14px !important;
      }
    }
  }

  .partRev {
    .PartnersReview {
      .swiper-wrapper {
        .swiper-slide {
          padding: 5px !important;
          // width: 210px !important;
          // height: 150px !important;

          .review {
            line-height: 16px !important;
          }
        }
      }
    }
  }

  .OftenGivenQuestions {
    .questionBox {
      p {
        line-height: 18px !important;
        margin-top: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .advantages {
    .advBox {
      .advDiv,
      .advDiv1 {
        width: 153px !important;
        height: 340px !important;

        div {
          p {
            font-size: 11px !important;
            line-height: 16px !important;
          }
        }
      }

      .advDiv {
        div {
          width: 150px !important;
          height: 150px !important;
        }
      }

      .advDiv1 {
        .connection {
          height: 240px !important;

          p {
            font-size: 11px !important;
            line-height: 16px !important;
          }
        }
      }
    }
  }

  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog {
    width: 250px !important;
    height: 340px !important;
    padding: 8px 15px 0px 15px !important;

    h1 {
      font-size: 18px !important;
      margin-bottom: 10px !important;
    }

    form {
      div {
        margin: 3px 0px !important;
      }

      label {
        font-size: 13px !important;
      }

      div {
        input {
          height: 10px !important;
        }
      }

      button {
        width: 180px !important;
        height: 35px !important;
        font-size: 12px !important;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .OurPartners {
    .partners {
      .mainContainer {
        .card {
          width: 150px !important;
          height: 150px !important;

          .front {
            img {
              height: 60px !important;
            }
          }

          .back {
            img {
              height: 35px !important;
            }

            h1,
            p {
              font-size: 12px !important;
              line-height: 15px !important;
            }

            p {
              line-height: 15px !important;
            }
          }
        }
      }
    }

    .partner-ad {
      .PartnerApp {
        width: 300px !important;
        height: 165px !important;

        .partnerAppBox1 {
          width: 175px !important;

          h1 {
            font-size: 11px !important;
            line-height: 16px !important;
          }

          p {
            width: 170px !important;
            line-height: 16px !important;
            font-size: 11px !important;
          }

          div {
            width: 170px !important;

            img {
              width: 76px !important;
              height: 21px !important;
            }
          }
        }

        img {
          width: 110px !important;
        }
      }
    }
  }

  .SubscriptionEmail {
    height: 180px !important;

    .subscriptionBox {
      .sub {
        width: 275px !important;

        h1 {
          font-size: 18px !important;
        }

        p {
          font-size: 12px !important;
          line-height: 16px !important;
        }
      }

      .email {
        width: 300px !important;

        form {
          height: 70px !important;

          input {
            // width: 180px !important;
            // margin-left: 10px !important;
            height: 35px !important;
            font-size: 13px !important;
            padding-left: 8px !important;
          }

          input::placeholder {
            font-size: 13px !important;
          }

          button {
            height: 30px !important;
            font-size: 12px !important;
          }

          .buttonActive {
            height: 30px !important;
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .FeedBack {
    .feedBox {
      .input {
        width: 300px !important;
        min-height: 360px !important;
        padding: 15px 20px !important;

        textarea::placeholder {
          font-size: 13px !important;
        }

        button {
          height: 40px !important;
        }

        .errorP {
          font-size: 13px !important;
        }

        div {
          label {
            font-size: 13px !important;
            margin: 0px !important;
          }

          div {
            input {
              height: 10px !important;
            }
          }
        }
      }
    }
  }
  .OurPartners {
    .partners {
      .mainContainer {
        .card {
          .front {
            img {
              height: 56px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .advantages {
    padding-top: 45px !important;
    height: 740px !important;

    .advBox {
      .advDiv {
        width: 137px !important;
        height: 310px !important;

        div {
          width: 137px !important;
          height: 138px !important;
          padding: 10px 0px !important;

          img {
            width: 35px !important;
          }

          p {
            line-height: 14px !important;
          }
        }
      }

      .advDiv1 {
        width: 137px !important;
        height: 310px !important;

        .connection {
          height: 220px !important;

          img {
            width: 30px !important;
          }

          h1 {
            font-size: 12px !important;
          }

          p {
            line-height: 14px !important;
          }

          div {
            button {
              width: 110px !important;
              height: 25px !important;
              font-size: 11px !important;
            }
          }
        }

        .lang {
          p {
            line-height: 14px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 347px) {
  .Tip {
    min-height: 600px !important;

    .tipBox {
      margin-left: 0px !important;
      height: 240px !important;

      h1 {
        font-size: 20px !important;
      }

      p {
        width: 286px !important;
        font-size: 12px !important;
      }

      .buttons {
        margin-left: 15px !important;

        div {
          button {
            width: 120px !important;
            height: 40px !important;
            font-size: 13px !important;
            margin-right: 10px !important;
          }
        }

        a {
          font-size: 12px !important;

          img {
            width: 15px !important;
          }
        }
      }
    }

    .images {
      margin-bottom: 5px !important;
    }
  }

  .howDoesItWork {
    h1 {
      font-size: 20px !important;
    }

    .menuDiv {
      width: 300px !important;

      div {
        width: 95px !important;
        height: 35px !important;
        font-size: 10px !important;
      }
    }

    .routeDiv {
      height: 560px !important;

      .Guest {
        div {
          height: 48% !important;

          img {
            width: 120px !important;
          }

          p {
            width: 250px !important;
            font-size: 11px !important;
            line-height: 16px !important;
            margin-top: 10px !important;
          }
        }
      }

      .Employee {
        .EmpDiv {
          height: 50% !important;

          img {
            width: 120px !important;
          }

          p {
            width: 250px !important;
            font-size: 11px !important;
            margin-top: 10px !important;
            line-height: 16px !important;
          }

          ul {
            margin-top: 10px !important;

            li {
              font-size: 11px !important;
              line-height: 16px !important;
            }
          }

          div {
            width: 190px !important;
            height: 40px !important;
            margin-top: 5px !important;

            a {
              img {
                width: 91px !important;
                height: 25px !important;
              }
            }
          }
        }
      }

      .Institution {
        div {
          height: 46% !important;

          img {
            width: 200px !important;
          }

          p {
            width: 250px !important;
            font-size: 11px !important;
            margin-top: 10px !important;
            line-height: 16px !important;
          }
        }
      }
    }
  }

  .advantages {
    margin-top: 80px !important;

    h1 {
      font-size: 20px !important;
    }
  }

  .HowToPay {
    margin-bottom: 250px !important;

    h1 {
      font-size: 20px !important;
      margin-bottom: 10px !important;
    }

    div {
      div {
        height: 42% !important;

        img {
          width: 120px !important;
          height: 148px !important;
        }

        .borderedImage {
          border-top: 3.5px solid rgb(209, 205, 249) !important;
          border-left: 3.5px solid rgb(209, 205, 249) !important;
          border-right: 3.5px solid rgb(209, 205, 249) !important;
        }

        p {
          width: 250px !important;
          font-size: 11px !important;
          line-height: 16px !important;
          margin-top: 10px !important;
        }
      }
    }
  }

  .FreeConnect {
    height: 470px !important;

    .sums {
      height: 310px !important;

      div {
        h1 {
          height: 30px !important;
        }

        p {
          font-size: 12px !important;
          line-height: 16px !important;
          margin-top: 10px !important;
        }
      }
    }

    .freeCnct {
      height: 120px !important;

      h1 {
        font-size: 20px !important;
      }

      p {
        line-height: 16px !important;
        font-size: 11px !important;
      }

      div {
        button {
          width: 200px !important;
          height: 35px !important;
          font-size: 15px !important;
        }
      }
    }
  }

  .OurPartners {
    .OurPartnersH1 {
      font-size: 20px !important;
    }
  }

  .OftenGivenQuestions {
    .oftH1 {
      font-size: 20px !important;
    }
  }

  .FeedBack {
    h1 {
      font-size: 20px !important;
      margin-bottom: 0px !important;
    }

    .feedBox {
      .text {
        p {
          font-size: 12px !important;
          line-height: 16px !important;
          margin-bottom: 15px !important;
        }
      }
    }
  }

  .FreeConnect {
    .freeCnct {
      h1 {
        font-size: 16px !important;
      }
    }
  }

  .SubscriptionEmail {
    .subscriptionBox {
      .sub {
        width: 269px !important;
      }

      // .email {
      //     form {
      //         input {
      //             width: 170px !important;
      //             height: 35px !important;
      //             margin-left: 17px !important;
      //         }

      //         button {
      //             width: 96px !important;
      //             height: 35px !important;
      //             right: 17px !important;
      //         }
      //     }
      // }
    }
  }
  .partRev .revH1 {
    font-size: 20px !important;
  }
}

.unSubscribePage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  background-color: #f5f5f5;

  .Box {
    width: 400px;
    min-height: 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    transition: 0.2s;
    padding-bottom: 60px;

    img {
      width: 150px;
      transition: 0.2s;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .emailInput {
      width: 92%;
      transition: 0.2s;
      margin-bottom: 30px;

      p {
        margin-top: 0px;
        margin-bottom: 10px;
      }

      input {
        width: 100%;
        height: 45px;
        font-size: 18px;
        padding-left: 10px;
        transition: 0.2s;
        box-sizing: border-box;
        outline: none;
        border-radius: 8px;
        border: none;
        background-color: white;
      }
    }

    .checkBoxes {
      display: flex;
      flex-direction: column;
      transition: 0.2s;
      padding: 0px 15px;

      .checkBoxLists {
        display: flex;
        align-items: start;
        // height: 40px;
        box-sizing: border-box;
        transition: 0.2s;

        p {
          font-size: 15px;
          margin-left: 5px;
          margin-top: -3px;
          margin-bottom: 20px;
        }
      }

      .otherInput {
        max-width: 100%;
        min-width: 100%;
        height: 100px;
        margin-bottom: 20px;
        padding-top: 5px;
        font-size: 18px;
        padding-left: 10px;
        transition: 0.2s;
        box-sizing: border-box;
        outline: none;
        border-radius: 8px;
        border: none;
      }

      button {
        transition: 0.2s;
        width: 100%;
        height: 45px;
        border-radius: 8px;
        font-size: 15px;
        border: 0.5px solid;
        background: rgb(0, 0, 0);
        cursor: pointer;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .unSubscribePage {
    height: 700px !important;
  }
}

@media screen and (max-width: 425px) {
  .Box {
    width: 95% !important;
  }
}
