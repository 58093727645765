.Terms{
    .termsHeader{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .termsTextBox{
        width: 700px;
        margin: 0 auto;
        h4{
            letter-spacing: 2px;
            font-weight: 400;
            font-family: 'Poppins';
            text-align: center;
            margin-bottom: 30px;
            font-size: 16px;
        }
        h3{
            font-size: 16px;
            font-weight: 400;
            text-align:center;
        }
        h5{
            font-size: 16px !important;
            font-weight: 400;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        p{
            text-align: justify;
        }
    }
}
@media screen and (max-width:750px) {
    .termsTextBox{
        width: 95% !important    ;
    }
}
@media screen and (max-width:550px) {
    .termsHeader{
        margin-bottom: 0px !important;
    }
}
@media screen and (max-width:500px) {
    .termsTextBox{
        p, h4, h3, h5{
            font-size: 14px !important;
        }
    }
    .termsHeader{
        height: 80px !important;
        img{
            width: auto;
            height: 35px !important;
        }
    }
}