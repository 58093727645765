.Privacy{
    .privacyHeader{
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .privacyTextBox{
        width: 700px;
        margin: 0 auto;
    }
}
@media screen and (max-width:750px) {
    .privacyTextBox{
        width: 95% !important    ;
    }
}
@media screen and (max-width:550px) {
    .privacyHeader{
        margin-bottom: 0px !important;
    }
}
@media screen and (max-width:500px) {
    .privacyTextBox{
        p{
            font-size: 14px !important;
        }
    
    }
    .privacyHeader{
        height: 80px !important;
        img{
            width: auto;
            height: 35px !important;
        }
    }
}